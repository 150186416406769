<template>
  <div class="flex flex-col">
    <label
      v-if="props.label"
      class="text-sm mb-1 text-black font-medium"
      v-text="props.label"
    />
    <div class="flex flex-row">
      <input
        ref="inputElement"
        v-model="childModel"
        readonly
        type="text"
        class="avv-input-tw"
        :placeholder="props.placeholder"
      />
      <button
        v-if="allowClear"
        class="avv-button w-1/6"
        role="button"
        @click="clear"
      >
        Clear
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue'
import flatpickr from 'flatpickr'

const props = withDefaults(
  defineProps<{
    modelValue: string
    format: string
    placeholder?: string
    label?: string
    helpText?: string
    allowClear?: boolean
  }>(),
  {
    label: '',
    helpText: '',
    allowClear: false
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const childModel = ref(props.modelValue)
const inputElement = ref()
const flatpickrInstance = ref()
const allowClear = ref(props.allowClear)

const clear = () => {
  flatpickrInstance.value?.clear()
}

function isValidDate(dateString: string) {
  if (dateString === '') return true
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false

  // Parse the date parts to integers
  const parts = dateString.split('/')
  const day = parseInt(parts[1], 10)
  const month = parseInt(parts[0], 10)
  const year = parseInt(parts[2], 10)

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) return false

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1]
}

watch(childModel, (value) => {
  emit('update:modelValue', value)
})

watch(
  inputElement,
  (input) => {
    if (input == null) return
    if (!isValidDate(inputElement.value.value)) {
      inputElement.value.value = ''
    }

    flatpickrInstance.value = flatpickr(inputElement.value, {
      dateFormat: 'Y-m-d',
      altInput: true,
      altFormat: props.format ?? 'd/m/Y',
      locale: 'en',
      onClose(_selectedDates, dateStr) {
        emit('update:modelValue', dateStr)
      }
    })

    if (childModel.value) {
      flatpickrInstance.value.setDate(childModel.value, false, props.format)
      childModel.value = inputElement.value.value
    }
  },
  { flush: 'post' }
)
</script>
